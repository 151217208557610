// src/components/Footer.js
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import '../css/footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section social-icons">
          <a
            href="https://www.facebook.com/biolane.hk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://www.instagram.com/biolanehk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Tycoon Group Holdings Limited. All rights reserved.</p>
      </div>
      <div className="footer-section">
        <p>
          Biolane法國貝兒於1972年由法國藥劑師Chabrand博士創立，是法國最受歡迎的嬰兒護理產品之一，亦是兒科及婦產科專家選用的信心品牌。
        </p>
        <p>
          Biolane
          產品性質溫和，成份天然，以溫柔呵護嬰兒及媽媽肌膚為原則，研究出極低敏的溫和配方，無酒精、無色素、無Parabens成份，全面照顧初生嬰兒、幼兒以至媽媽不同的肌膚需要。
        </p>
      </div>
    </footer>
  );
}

export default Footer;
