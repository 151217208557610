import React from "react";
import { useNavigate } from "react-router-dom";
const terms = `1.「Biolane」 (下稱「本會」)由「Tycoon Group Holdings limited」(下稱「本公司」)運作。
2. 入會資格：申請人於提交入會申請當日必須已年滿18歲，並於當時為懷孕中的媽媽或育有子女年齡為48個月或以下的媽媽。
3. 申請人可從不同途徑遞交申請，唯每位合資格的媽媽只可擁有一個會藉。 
4. 申請人於提交入會申請時，必須提供所有於申請表上被列為「必須」的資料，資料一經提交後，客戶服務人員會於5-10個工作天內核實所提交的資料，在有需要時會聯絡申請人手提電話以作核實。
5. 迎新禮物可於活動期間在會場派發。 
6. 如發現申請人個人資料不真確或不完整，該申請入會資格將被取消而不作另行通知；如發現會員個人資料不真確或不完整，本會有權立即終止該會員所享有的福利及推廣活動。
7. 會藉有效期為子女年齡滿72個月生日當天。若會員有多於一位子女，會員可聯絡本會或到會員網站修改子女的數目及出生資料，以便日後收取最新資訊及推廣優惠。而會藉有效期將會自動更新，並跟據年齡最小的一位子女的出生日期計算。
8. 除個別推廣優惠外，成功入會的會員即同意接受本公司的私隱政策、會藉條款及細則，同時同意並要求本公司及其關聯公司或協助其作直接營銷之第三方使用我的個人資料向我提供有關孕婦、嬰幼兒營養、食品及護理的產品、服務及教育資訊。
9. 如沒有合理原因取消會藉，並於半年內重新申請入會將不獲發迎新禮物。 
10. 當本會收到申請人提供的資料，本公司會確保儲存系統的安全性。但是，由於網路資料的傳輸不能保證百分之百的安全，本公司無法確保申請人傳送給本會的資料或接收自本會的資料的安全，請申請人自行承擔網路資料傳輸安全的風險。
11. 本會保留審批入會申請及發放所有禮物優惠的最終決定權。 
12. 本會不定期為會員提供各項會員福利及優惠、推出新優惠、更改或撤回任何優惠，而無需事先通知。
13. 如欲更改會員資料，會員可透過以下其中之一種方式聯絡本會﹕ 
a. 電話：香港 (852) 26616727
b. 電郵：info@tapgl.com
14. 如有任何爭議，本公司保留最終決定及解釋權。`;
function TermsAndConditions() {
  const navigate = useNavigate();

  const onBack = (e) => {
    navigate(-1);
  };

  return (
    <div className="container">
      <h1>會籍條款及細則</h1>
      <pre style={{ whiteSpace: "pre-wrap" }}>{terms}</pre>
      <div className="button-container">
        <button onClick={() => onBack()}>返回</button>
      </div>
    </div>
  );
}

export default TermsAndConditions;
