function recursivelyNullifyUndefinedValues(obj) {
    Object
      .entries(obj)
      .forEach(([key, value]) => {
        if (!!value && (typeof value === 'object')) {
  
          recursivelyNullifyUndefinedValues(value);
  
        } else if (value === undefined) {
  
          obj[key] = null;
        }
      });
    return obj;
  }
  

export class SubmitData {
  getRequiredValue() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      region: this.region,
      district: this.district,
      // address: this.address,
      status: this.status,
      edd: this.edd,
      isAgreedAllTerms: this.isAgreedAllTerms,
    };
  }

  fieldNameCN(d) {
    switch (d) {
      case "firstName":
        return "姓氏";
      case "lastName":
        return "名字";
      case "email":
        return "電郵地址";
      case "region":
        return "地區";
      case "district":
        return "分區";
      case "address":
        return "通訊地址";
      case "status":
        return "嬰兒資料";
      case "edd":
        return "預產期";
      case "isAgreedAllTerms":
        return "條款及細則";
    }
  }

  checkRequiredField() {
    let missingList = [];
    const requiredValues = this.getRequiredValue();
    Object.entries(requiredValues).forEach((v) => {
      // console.log(v)
      if (v[1] === "" || v[1] === false) {
        missingList.push(v[0]);
        console.log(missingList);
      }
    });
    return missingList.map((e) => this.fieldNameCN(e));
  }

  getJsonData() {
    const jsonData = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      status: this.status,
      ageMonthRange: this.ageMonthRange,
      kidsNumber: this.kidsNumber,
      edd: this.edd,
      address: this.address,
      interests: this.interests,
      // specialOffer: this.specialOffer,
      // isAgreedAllTerms: this.isAgreedAllTerms,
      createdAt: new Date().toISOString(),
    };
    return recursivelyNullifyUndefinedValues(jsonData);
  }
}

export const SubmitDataType = Object.freeze({
  PERSONAL: "personal",
  DELIVERY: "delivery",
  FAMILYSTATUS: "familystatus",
  TERMSANDCONS: "termsandcons",
});
