import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { onSubmitForm } from "../api/api";
import { SubmitData, SubmitDataType } from "../model/model";

function PhoneVerification() {
  const navigate = useNavigate();
  const requiredList = [
    "firstName",
    "lastName",
    "email",
    "region",
    "district",
    "address",
    "status",
    "edd",
    "isAgreedAllTerms",
  ];
  const [phoneNumber, setPhoneNumber] = useState("+852-");
  const [verificationCode, setVerificationCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [confirmResult, setConfirmResult] = useState(null);
  const [submitData, setSubmitData] = useState(new SubmitData());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setData = (data, dataType) => {
    submitData.phone = phoneNumber;
    if (dataType === SubmitDataType.PERSONAL) {
      submitData.email = data.email;
      submitData.firstName = data.firstName;
      submitData.lastName = data.lastName;
      submitData.interests = data.interests;
      submitData.address = data.address;
    }
    if (dataType === SubmitDataType.DELIVERY) {
      submitData.region = data.region;
      submitData.district = data.district;
      submitData.address = data.address;
    }
    if (dataType === SubmitDataType.FAMILYSTATUS) {
      submitData.status = data.status;
      submitData.edd = data.edd;
      submitData.ageMonthRange = data.ageMonthRange;
      submitData.kidsNumber = data.kidsNumber;
    }
    if (dataType === SubmitDataType.TERMSANDCONS) {
      submitData.isAgreedAllTerms = data.isAgreedAllTerms;
    }
    setSubmitData(submitData);
    // console.log(submitData)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const missingField = submitData.checkRequiredField();
    if (missingField.length > 0) {
      alert(`${missingField} 為必填項目`);
      return;
    }
    if (isValid) {
      // TODO:
      try {
        setIsSubmitting(true);
        const confirmation = await confirmResult.confirm(verificationCode);
        console.log(confirmation);
        console.log(submitData.getJsonData());
        await onSubmitForm(confirmation.user.uid, submitData.getJsonData());
        navigate("/success");
      } catch (e) {
        console.error(e);
        setIsSubmitting(false);
        alert("Invalid Verification Code");
      }
    } else {
      alert("Please enter a valid phone number");
    }
  };

  return (
    <div className="container">
      <img src="/finished.jpeg" alt="finished" className="banner-image" />
    </div>
  );
}

export default PhoneVerification;
