// src/Banner.js
import React from 'react';

function Banner({ title }) {
  return (
    <div className="banner">
      <picture>
        <source media="(min-width: 768px)" srcSet="/banner.jpeg" />
        <source media="(max-width: 767px)" srcSet="/banner_m.jpeg" />
        <img src="/banner_m.jpeg" alt="Banner" className="banner-image" />
      </picture>
    </div>
  );
}

export default Banner;