import * as React from "react";

import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import Footer from "./components/Footer";
import './css/App.css';
import './css/input.css';
import PersonalDetails from './pages/PersonalDetailsPage';
import PhoneVerification from './pages/PhoneVerificationPage';
import SuccessPage from './pages/SuccessPage';
import TermsAndConditions from "./pages/TermsPage";

function App() {
  return (
    <div className="app">
      {/* <Logo /> */}
      <Router>
        <Routes>
          <Route exact path="/" element={<PhoneVerification />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/personal-details" element={<PersonalDetails />} />
          <Route path="/success" element={<SuccessPage/>} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
