import {getFirestore} from "@firebase/firestore";
import {initializeApp, setLogLevel} from "firebase/app";
import {getAuth} from "firebase/auth";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./css/index.css";
import reportWebVitals from "./reportWebVitals";

export const firebaseConfig = {
  apiKey: "AIzaSyDDAe-0gyh1g_Wz5nfhEUepnEwbE_QFlZE",
  authDomain: "biolane-web.firebaseapp.com",
  projectId: "biolane-web",
  storageBucket: "biolane-web.appspot.com",
  messagingSenderId: "240170165134",
  appId: "1:240170165134:web:1ec41695024215e80ffadf",
  measurementId: "G-YH22SDC8VZ",
};

initializeApp(firebaseConfig);
const auth = getAuth();
export const db = getFirestore()
setLogLevel("debug")
auth.useDeviceLanguage();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
